body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  /*height:90vh;  */
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#container {
  width: 80vw;
  /*border: 4px dashed rgba(4, 4, 5, 0.1);*/
  min-height: 140px;
  padding: 32px;
  position: relative;
  border-radius: 16px;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  text-align:left;
  word-break: break-word;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 80%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
  margin: none;
}

#status {
  color:  #254cdd;
  font-size: 14px;
  font-weight: 600;
}

button {
  padding: 9px 16px;
  /*max-height: 40px;*/
  *box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 20%);
  border-color:  #254cdd ;
  border: none;
  background: none;
  color:white;
  background-color: #254cdd;
  border-radius: 15px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
  min-width: 160px;
  margin-top: 70px;
  font-family: Rubik, sans-serif;
}

#logo {
  float:left;
  display: flex;
  margin-left: -10px;
  margin-top: -16px;
  width: 210px;
}

#walletButton {
  padding: 7px 16px;
  background-color: white;
  float:right;
  border: 2px solid #254cdd ;
  color:#254cdd;
  margin-top: 0px;

}

h2 {
  display: block;
  clear:both;
  font-weight:400;
}

a {
  color:#254cdd
}

.card {
/*  width: 300px;
  height: 200px;
*/  border-radius: 10px;
  border-color: #0000ff;
  border-width: 3px;
  margin: 20px;
  padding: 20px;
  background-color: #eeeeff;
  display: inline-block;
  text-align: center;
  transition: background-color 1s;
  width: 40hw;
}

.card:hover {
  background-color: #87ceeb;
}

.card img {
  padding: 20px;
}

button {
  transition: border;
  border: 2px solid #eeeeff;
  padding:  20px 20px 20px 20px;
}

button:hover {
  border: 2px solid #87ceeb;
  background-color: #ffffff;
  color: #333333;
}

.charities img {
  height: 80px;
}

#countdown {
  background: 
    linear-gradient(white, white) padding-box,
    repeating-linear-gradient(45deg, red 0 10px, transparent 5px 15px) border-box;
  border-radius: 20px;
  border: 4px solid transparent;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

